<template>
  <div>
    <v-dialog
        v-model="scoresDialog"
        max-width="75vw"
        @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Scores</span>
        </v-card-title>
        <v-card-text>
          <!-- INDEX OF SCORES -->
          <v-data-table
              :headers="headers"
              :items="scores"
              :items-per-page="15"
              class="elevation-1"
          >
<!--            <template v-slot:item.is_ended="{item}">-->
<!--              <v-icon small v-if="item.is_ended" color="green">mdi-check</v-icon>-->
<!--              <v-icon small v-else color="red">mdi-close</v-icon>-->
<!--            </template>-->
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="#f27e42"
              rounded
              @click="closeDialog"
              class="white--text"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MeetingExam",
  props: ["scoresDialog", "selectedMeeting", "scores"],

  data() {
    return {
      headers: [
        {text: 'User', value: 'user.name'},
        {text: 'Exam Score', value: 'exam_score'},
      ]
    }
  },

  mounted() {

  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog')
    }
  }
}
</script>

<style>

</style>