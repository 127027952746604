<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
        <v-alert
            style="z-index: 999999999"
            v-model="NOTIFICATION_STATUS"
            :color="NOTIFICATION_COLOR"
            elevation="24"
            type="success"
            dismissible
        >{{ NOTIFICATION }}
        </v-alert>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <v-dialog
        v-model="homeworksDialog"
        max-width="75vw"
        @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Homeworks</span>
        </v-card-title>
        <v-card-text>
          <!-- ASSIGN TASKS -->
          <v-row class="mb-16" v-if="selectedClas && selectedClas.broadcaster.id == user.data.vod_user_id">
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-text-field hide-details label="Title" v-model="task.title"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-select :items="users" item-value="vod_user_id" item-text="name" hide-details label="Assignees" multiple
                        v-model="task.assignees"></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea outlined hide-details label="Description" v-model="task.description"></v-textarea>
            </v-col>
            <v-col cols="12" style="align-content: center; text-align: center; padding-top: 30px;">
              <v-btn rounded color="#579e81" class="white--text" v-if="selectedTask" @click="updateTask">Update</v-btn>
              <v-btn rounded color="#febb69" class="white--text ml-2" v-if="selectedTask" @click="cancelEditTask">
                Cancel
              </v-btn>
              <v-btn rounded color="#579e81" class="white--text" v-else @click="assignTask">Assign</v-btn>
            </v-col>
          </v-row>

          <!-- INDEX OF TASKS -->
          <v-expansion-panels multiple>
            <v-expansion-panel v-for="task in tasks" :key="task.id">
              <v-expansion-panel-header>
                <v-row no-gutters>
                  <v-col cols="4" style="align-self: center">
                    <v-icon v-if="task.status === 'done'" color="green">mdi-check</v-icon>
                    {{ task.title }}
                  </v-col>
                  <v-col class="text--secondary" style="align-self: center">
                    Assignees :
                    <span v-for="user in task.assignees" :key="user.id">{{ user.name }}, </span>
                  </v-col>
                  <v-col cols="2" style="text-align: center">
                    <v-btn small v-if="selectedClas && selectedClas.broadcaster.id == user.data.vod_user_id" color="#febb69" class="white--text" @click="editTask(task)">Edit</v-btn>
                    <v-btn small v-if="selectedClas && selectedClas.broadcaster.id == user.data.vod_user_id" color="#f27e42" class="white--text ml-2" @click="deleteTask(task)">Delete</v-btn>
                    <v-btn small v-if="task.status === 'in-progress'" color="#579e81" class="white--text ml-2" @click="changeStatus(task, 'done')">Done</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="text-align: left">
                {{ task.description }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="#f27e42"
              rounded
              @click="closeDialog"
              class="white--text"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "MeetingHomework",

  props: ['homeworksDialog', 'selectedClas', 'users', 'tasks'],

  data() {
    return {
      selectedTask: null,

      task: {
        title: null,
        description: null,
        assignees: [],
      }
    }
  },

  mounted() {},

  methods: {
    assignTask() {
      window.axios.post('/api/classes/tasks/create', {
        title: this.task.title,
        description: this.task.description,
        clas_id: this.selectedClas.id,
        assignees: this.task.assignees
      })
          .then(() => {
            this.$emit('get-tasks')
          })
          .catch((err) => {
            this.NOTIFICATION_STATUS = true
            this.NOTIFICATION_COLOR = 'red'
            this.NOTIFICATION = err.response.data.message
          })
    },

    editTask(task) {
      this.selectedTask = task
      this.task.title = task.title
      this.task.description = task.description
      this.task.assignees = task.assignees
      this.task.assignees.forEach((item, index) => {
        this.task.assignees[index]['vod_user_id'] = item.id
      })
    },

    cancelEditTask() {
      this.selectedTask = null
      this.task.title = null
      this.task.description = null
      this.task.assignees = []
    },

    updateTask() {
      this.task.assignees.forEach((item, index) => {
        if (!Number.isInteger(item)) {
          this.task.assignees[index] = item.vod_user_id
        }
      })

      window.axios.post('/api/classes/tasks/update/' + this.selectedTask.id, {
        title: this.task.title,
        description: this.task.description,
        clas_id: this.selectedClas.id,
        assignees: this.task.assignees
      })
          .then(() => {
            this.$emit('get-tasks')
          })
          .catch((err) => {
            this.NOTIFICATION_STATUS = true
            this.NOTIFICATION_COLOR = 'red'
            this.NOTIFICATION = err.response.data.message
          })
    },

    deleteTask(task) {
      console.log(task)
      window.axios.get('/api/classes/tasks/delete/' + task.id)
          .then(() => {
            this.$emit('get-tasks')
          })
          .catch((err) => {
            this.NOTIFICATION_STATUS = true
            this.NOTIFICATION_COLOR = 'red'
            this.NOTIFICATION = err.response.data.message
          })
    },

    changeStatus(task, status) {
      window.axios.post('/api/classes/tasks/change-status/' + task.id, {
        status
      })
          .then(() => {
            this.$emit('get-tasks')
          })
          .catch((err) => {
            this.NOTIFICATION_STATUS = true
            this.NOTIFICATION_COLOR = 'red'
            this.NOTIFICATION = err.response.data.message
          })
    },

    closeDialog() {
      this.$emit('close-dialog')
    }
  }
}
</script>

<style>

</style>