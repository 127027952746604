<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
        <v-alert
            style="z-index: 999999999"
            v-model="NOTIFICATION_STATUS"
            :color="NOTIFICATION_COLOR"
            elevation="24"
            type="success"
            dismissible
        >{{ NOTIFICATION }}
        </v-alert>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <v-dialog
        v-model="questionsDialog"
        max-width="75vw"
        @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Questions</span>
        </v-card-title>
        <v-card-text>
          <!-- CRUD QUESTION -->
          <v-row class="mb-16" v-if="(selectedMeeting && selectedMeeting.can_join) || question.title">
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-text-field hide-details label="Title" v-model="question.title"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea outlined hide-details label="Description" v-model="question.description"></v-textarea>
            </v-col>
            <v-radio-group v-model="question.correctAnswer">
              <v-row class="mx-1">
                <v-col cols="12" xl="6" lg="6">
                  <v-textarea outlined hide-details label="Option 1" v-model="question.answers[0]"></v-textarea>
                  <v-radio label="Is Correct Answer" value="0"></v-radio>
                </v-col>
                <v-col cols="12" xl="6" lg="6">
                  <v-textarea outlined hide-details label="Option 2" v-model="question.answers[1]"></v-textarea>
                  <v-radio label="Is Correct Answer" value="1"></v-radio>
                </v-col>
                <v-col cols="12" xl="6" lg="6">
                  <v-textarea outlined hide-details label="Option 3" v-model="question.answers[2]"></v-textarea>
                  <v-radio label="Is Correct Answer" value="2"></v-radio>
                </v-col>
                <v-col cols="12" xl="6" lg="6">
                  <v-textarea outlined hide-details label="Option 4" v-model="question.answers[3]"></v-textarea>
                  <v-radio label="Is Correct Answer" value="3"></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
            <v-col cols="12" style="align-content: center; text-align: center; padding-top: 30px;">
              <v-btn v-if="selectedQuestion && selectedMeeting && selectedMeeting.can_join" rounded color="#579e81" class="white--text" @click="updateQuestion">Save</v-btn>
              <v-btn v-if="selectedQuestion" rounded color="#febb69" class="white--text ml-2"  @click="cancelEditQuestion">
                Cancel
              </v-btn>
              <v-btn v-if="!selectedQuestion && selectedMeeting && selectedMeeting.can_join" rounded color="#579e81" class="white--text" @click="createQuestion">Save</v-btn>
            </v-col>
          </v-row>

          <!-- INDEX OF QUESTIONS -->
          <v-expansion-panels multiple>
            <v-expansion-panel v-for="question in questions" :key="question.id">
              <v-expansion-panel-header>
                <v-row no-gutters>
                  <v-col cols="10" style="align-self: center">
                    {{ question.title }}
                  </v-col>
                  <v-col cols="2" style="text-align: center">
                    <v-btn small v-if="selectedMeeting" color="#febb69" class="white--text" @click="editQuestion(question)">Edit</v-btn>
                    <v-btn small v-if="selectedMeeting && selectedMeeting.can_join" color="#f27e42" class="white--text ml-2" @click="deleteQuestion(question)">Delete</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="text-align: left">
                {{ question.description }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="#f27e42"
              rounded
              @click="closeDialog"
              class="white--text"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MeetingExam",
  props: ["questionsDialog", "selectedMeeting", "questions"],

  data() {
    return {
      selectedQuestion: null,

      question: {
        title: null,
        description: null,
        answers: [],
        correctAnswer: null,
      },
    }
  },

  mounted() {},

  methods: {
    createQuestion() {
      let answers = []
      this.question.answers.forEach((item, index) => {
        answers.push({
          answer: item,
          is_true: index == this.question.correctAnswer
        })
      })

      window.axios.post('/api/classes/meeting/questions/create', {
        title: this.question.title,
        description: this.question.description,
        meeting_id: this.selectedMeeting.id,
        answers
      })
          .then(() => {
            this.$emit('get-questions')
          })
          .catch((err) => {
            this.NOTIFICATION_STATUS = true
            this.NOTIFICATION_COLOR = 'red'
            this.NOTIFICATION = err.response.data.message
          })
    },

    editQuestion(question) {
      this.selectedQuestion = question
      this.question.title = question.title
      this.question.description = question.description

      this.question.answers = []
      question.answers.forEach((item, index) => {
        this.question.answers.push(item.answer)
        if (item.is_true) {
          this.question.correctAnswer = String(index)
        }
      })
    },

    cancelEditQuestion() {
      this.selectedQuestion = null
      this.question.title = null
      this.question.description = null
      this.question.assignees = []
    },

    updateQuestion() {
      let answers = []
      this.question.answers.forEach((item, index) => {
        answers.push({
          answer: item,
          is_true: index == this.question.correctAnswer
        })
      })

      window.axios.post('/api/classes/meeting/questions/update/' + this.selectedQuestion.id, {
        title: this.question.title,
        description: this.question.description,
        meeting_id: this.selectedMeeting.id,
        answers
      })
          .then(() => {
            this.$emit('get-questions')
          })
          .catch((err) => {
            this.NOTIFICATION_STATUS = true
            this.NOTIFICATION_COLOR = 'red'
            this.NOTIFICATION = err.response.data.message
          })
    },

    deleteQuestion(question) {
      window.axios.get('/api/classes/meeting/questions/delete/' + question.id)
          .then(() => {
            this.$emit('get-questions')
          })
          .catch((err) => {
            this.NOTIFICATION_STATUS = true
            this.NOTIFICATION_COLOR = 'red'
            this.NOTIFICATION = err.response.data.message
          })
    },

    closeDialog() {
      this.$emit('close-dialog')
    }
  }
}
</script>

<style>

</style>